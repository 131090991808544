import request from '../utils/request'
import baseUrl from './baseUrl'
//游戏列表
export const gameList = (data) => request({
    url: baseUrl + '/game/selectForBack',
    method: 'POST',
    data
  })
//添加/修改游戏
export const addGame = (data) => request({
    url: baseUrl + '/game/add',
    method: 'POST',
    data
  })
//启用/禁用
export const isTurnOn = (data) => request({
    url: baseUrl + '/game/modifyStatus',
    method: 'POST',
    data
  })
//删除游戏
export const delGame = (data) => request({
    url: baseUrl + '/game/deleteById',
    method: 'POST',
    data
  })
//查看详情
export const gameDetail = (data) => request({
    url: baseUrl + '/game/selectForId',
    method: 'POST',
    data
  })