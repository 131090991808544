<template>
<!-- 游戏管理页 -->
  <div class="birthdayRight">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>游戏标题：</span>
      <el-input
        v-model="formData.title"
        placeholder="请输入活动标题"
      ></el-input>
      <span style="margin-left:15px;">游戏状态：</span>
        <el-select v-model="optStatus" placeholder="请选择">
          <el-option
            v-for="item in statusOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      <!-- <el-select
        v-model="formData.status"
        clearable
        @clear="onClear(1)"
        placeholder="请选择"
      >
        <el-option
          v-for="item in typeList"
          :key="item.value"
          :label="item.lable"
          :value="item.value"
        />
      </el-select> -->
      <el-button type="primary" class="btn" @click="searchGameList()"
        >查询</el-button
      >

      <el-button
        type="primary"
        class="btn"
        @click="$router.push('/addGame')"
      >
        添加
      </el-button>
    </div>
    <el-table
      border
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="gameId" label="游戏Id"> </el-table-column>
      <el-table-column prop="title" label="游戏标题"> </el-table-column>
      <el-table-column prop="beginTime" label="活动开始时间"> </el-table-column>
      <el-table-column prop="endTime" label="活动截止时间"> </el-table-column>
      <el-table-column prop="strStatus" label="游戏状态"> </el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="{ row }">
             <el-button
            size="mini"
            type="primary"
            style="margin-left: 10px"
            @click="ablGame(row.gameId)"
            v-if="row.status == 0"
          >
            启用
          </el-button>
                <el-button
            type="primary"
            size="mini"
            style="margin-left: 10px"
            @click="disGame(row.gameId)"
            v-if="row.status == 1"
          >
            禁用
          </el-button>
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 10px"
            @click="dialogVisible3 = true"
          >
            删除
          </el-button>
        <el-button type="primary" size="mini" @click="getGameDetail(row.gameId)">查看详情</el-button>
               <el-dialog
              title="提示"
              :visible.sync="dialogVisible"
              width="30%"
              :before-close="handleClose">
              <span>是否启用？</span>
     <span slot="footer" class="dialog-footer">
           <el-button @click="dialogVisible = false">取 消</el-button>
           <el-button type="primary" @click="turnOn(row.gameId)">确 定</el-button>
      </span>
    </el-dialog>
               <el-dialog
     title="提示"
     :visible.sync="dialogVisible2"
     width="30%"
     :before-close="handleClose">
     <span>是否禁用？</span>
     <span slot="footer" class="dialog-footer">
           <el-button @click="dialogVisible2 = false">取 消</el-button>
           <el-button type="primary" @click="turnOff(row.gameId)">确 定</el-button>
      </span>
    </el-dialog>
               <el-dialog
     title="提示"
     :visible.sync="dialogVisible3"
     width="30%"
     :before-close="handleClose">
     <span>是否删除？</span>
     <span slot="footer" class="dialog-footer">
           <el-button @click="dialogVisible3 = false">取 消</el-button>
           <el-button type="primary" @click="removeGame(row.gameId)">确 定</el-button>
      </span>
    </el-dialog>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { gameList,isTurnOn,delGame,gameDetail } from "../../../api/gameTools";
export default {
  name: "activeList",

  data() {
    return {
      gameStatus:[],
        tableData:[],
      showAddress: false,
      address: "",
      formData: {
        status: null,
        title: "",
        pageSize: 10,
        currentPage: 1,
      },
      multipleSelection: [],
      tableData: [],
      pagination: {},
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      detailData:[],
      gameId:'',
      dis:'',
      abl:'',
      statusOption:[{
          value: -2,
          label: '全部'
        }, {
          value: 1,
          label: '正常'
        }, {
          value: 0,
          label: '禁用'
        }],
        optStatus:'',
    };
  },
  created() {
    // this.getTableList();
    this.getGameList();
  },
  methods: {
      disGame(row){
        console.log('disGame',row)
        this.dis = row
          this.dialogVisible2 = true
      },
      ablGame(row){
        this.able = row
        console.log('ablGame',row)
        this.dialogVisible=true
      },
      closeModal(){
        this.turnOn()
        this.dialogVisible=false
      },
       handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      //搜索游戏列表
      async searchGameList(){
        const {data} = await gameList({
           pageSize:this.formData.pageSize,
          currentPage:this.formData.currentPage,
          title:this.formData.title,
          status:this.optStatus,
         })
        this.tableData = data.list;
      },
      //游戏详情
      async getGameDetail(row){
          const { data } = await gameDetail({
              gameId:row
          })
         this.detailData = data.data[0]
         this.$router.push(`/editGame?gameId=${row}`)
      },
      //游戏列表
      async getGameList(){
          const {data} = await gameList({
              pageSize:this.formData.pageSize,
              currentPage:this.formData.currentPage,
          })
          this.tableData = data.list;
          this.pagination = data.pagination;
      },
      //启动
      async turnOn(row,status){
        //const { data } = 
        await isTurnOn({
          gameId:this.able,
              status:1,
          })
            console.log('开启游戏',this.able)
        setTimeout(() => {
            this.dialogVisible =false
        }, 200);
            this.$message({ message: "启用成功", type: "success" }); 
            this.getGameList()
      },
      //禁用
      async turnOff(){
       
          const { data } = await isTurnOn({
            gameId:this.dis,
            status:0,
          })
           console.log('禁用',this.dis)
            setTimeout(() => {
                this.dialogVisible2 =false
        }, 200);
                this.$message({ message: "禁用成功", type: "success" }); 
                this.getGameList()
      },
      //删除游戏
      async removeGame(row){
          const {data} = await delGame({
              gameId:row
          })
         setTimeout(() => {
            this.dialogVisible3 =false
            this.$message({ message: "删除成功", type: "success" }); 
        }, 200);
          this.getGameList();
      },
    onShowAddress(id) {
      this.address = "components/navPages/navPages?activityId=" + id;
      this.showAddress = true;
    },
    copyinviteUrl() {
      this.$nextTick(() => {
       var inputElement =  document.getElementById("wrapper");
       //选中input框的内容
       inputElement.select();
        // 执行浏览器复制命令
       document.execCommand("Copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },

    hideVisible() {
      this.dialogVisible2 = false;
    },
    handleSelectionChange(arr) {
      var data = [];
      arr.forEach((item) => {
        data.push(item.activityId);
      });
      this.multipleSelection = data;
      console.log(this.multipleSelection);
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getGameList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getGameList();
    },
    onInput(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.birthdayRight {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .btn_box {
    float: left;
    margin-top: 50px;
  }
  .add {
    margin-top: 20px;
  }
  .el-table {
    margin-top: 50px;
  }
  .search_box {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .el-input {
      width: 200px;
    }
    .btn {
      margin-left: 20px;
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }
  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }
  .tips {
    // text-align: right;
    color: orangered;
  }
  .grade_list {
    padding: 0 10px 10px;
    .grade_item {
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 20px;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
      .grade_name {
        font-weight: bold;
        margin: 0px 0 10px;
      }
      .form_item {
        display: flex;
        .el-form-item {
          flex: 1;
          .el-input-number {
            width: 100%;
          }
        }
      }
    }
  }
  .btn_box {
    margin-top: 24px;
  }
  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  /deep/.popconfirm {
    margin-right: 10px;
  }
  #address {
    display: flex;
    align-items: center;

    .el-input {
      width: 350px;
    }
  }
}
</style>